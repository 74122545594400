export class Unread {

    private _proposals: number;
    private _legals: number;
    private _informs: number;
    private _rto: number;

    constructor(unreadProposals: number, unreadInforms: number, unreadLegals: number, unreadRto: number) {

        this._proposals = unreadProposals;
        this._legals = unreadLegals;
        this._informs = unreadInforms;
        this._rto = unreadRto;

    }

    /**
     *
     * @returns {number}
     */
    public get proposals() {
        return this._proposals;
    }

    /**
     *
     * @param {number} proposals
     */
    public set proposals(proposals: number) {
        this._proposals = proposals;
    }

    /**
     *
     * @returns {number}
     */
    public get legals() {
        return this._legals;
    }

    /**
     *
     * @param {number} legals
     */
    public set legals(legals: number) {
        this._legals = legals;
    }

    /**
     *
     * @returns {number}
     */
    public get informs() {
        return this._informs;
    }

    /**
     *
     * @param {number} informs
     */
    public set informs(informs: number) {
        this._informs = informs;
    }

    /**
     *
     * @returns {number}
     */
    public get rto() {
        return this._rto;
    }

    /**
     *
     * @param {number} rto
     */
    public set rto(rto: number) {
        this._rto = rto;
    }

}
