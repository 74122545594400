import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { User } from './models/user/user';
import { SessionDataService } from './providers/sessionData/session-data.service';
import { LanguageService } from './providers/language/language.service';
import { Router } from '@angular/router';
import { PushNotificationService } from './providers/notification/push.notification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  currentUser: User;
  isLogged: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private languageService: LanguageService,
    private sessionDataService: SessionDataService,
    private push: PushNotificationService) {

    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.languageService.setInitialAppLanguage();
      this.currentUser = this.sessionDataService.getUser();
      this.push.initializePushCheck();
      this.splashScreen.hide();
    }, error => {
      console.error(error)
    });
  }


  ngOnInit(): void {

  }

  ngOnChanges(): void {

  }

}
