import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL } from '../../global/global';
import { Storage } from '@ionic/storage';
import { Language } from 'src/app/models/language/language';
import { ApiclientService } from '../apiclient/apiclient.service';
import { SessionDataService } from '../sessionData/session-data.service';


const LGN_KEY = "SELECTED_LANGUAGE";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selectedLanguage = "";
  constructor(private translate: TranslateService, private storage: Storage, private apiClient: ApiclientService, private sessionService: SessionDataService) {

  }

  /**
   * This method set the language to app
   */
  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    language = this.checkLanguage(language);
    this.translate.setDefaultLang(language);
    this.storage.get(LGN_KEY).then(val => {
      if (val) {
        this.setLanguages(val);
        this.selectedLanguage = val
      }
    })
  }

  /**
   * This method checks that the language is 'en' or 'es', if it is none, by default the 'en' will be activated.
   * @param language the language to check
   */
  checkLanguage(language: string): string {

    if (language !== 'es' && language !== 'en') {
      language = 'en';
    }

    return language;
  
  }

  /**
   * This method generate a new promise when it takes the value of language from storage
   */
  getLanguage() {

    let language = this.translate.getBrowserLang();
    language = this.checkLanguage(language);

    return new Promise((resolve, reject) => {
      this.storage.get(LGN_KEY).then(val => {

        if (this.sessionService.checkSessionStorage()) {
          let userLng=this.sessionService.getUser().lang
          if ( userLng!== val) {
            this.setLanguages(userLng);
            resolve(userLng);
          }
        }

        if (val === null) {
          this.setLanguages(language);
          resolve(language);
        }
        resolve(val);

      }).catch(error => {

        this.setLanguages(language);
        reject(language);

      });
    });

  }
  /**
   * This method set the new language to the app
   * @param lang the language selected
   */
  setLanguages(lang) {

    this.translate.use(lang);
    this.selectedLanguage = lang;
    this.storage.set(LGN_KEY, lang);
    
    if (this.sessionService.checkSessionStorage()) {
      this.setLanguageFromApi(lang);
      this.sessionService.changeSessionLang(lang);
    }

  }

  getSelectedLang() {
    return this.selectedLanguage;
  }

  /**
   * This method returns all available languages
   */
  getGlobalLanguages() {
    return GLOBAL.LANGUAGE_OPTIONS;
  }

  getLanguages() {

    let arr_langs = []
    let langs = GLOBAL.LANGUAGE_OPTIONS;

    if (langs.length > 0) {

      arr_langs = langs.map(lang => {
        return this.getLanguageObject(lang);
      });

    }

    return arr_langs;

  }

  getCurrentLanguageInObject() {

    let currentLang = this.selectedLanguage;
    let langs = this.getLanguages();
    let obj_lang = null;
    let arr_langs = [];

    if (langs.length > 0) {

      for (let lang of langs) {
        if (currentLang === lang.id) obj_lang = lang;
      }
    }

    arr_langs[0] = obj_lang;

    return arr_langs;

  }

  getLanguageObject(lang: any): Language {

    return new Language(
      (lang['id'] !== undefined) ? lang['id'] : "",
      (lang['name'] !== undefined) ? lang['name'] : "",
    )

  }

  /**
   * Get the language of user from api rest
   * @param params 
   */
  getLanguageFromApi(params) {
    return this.apiClient.doGetCall('user_language', params);
  }

  /**
   * Set the language of user from api rest to set into the aplication
   * @param lang Params to send and set {'lang': lang};
   */
  setLanguageFromApi(lang: string) {
    
    let params = { 'lang': lang };
    this.apiClient.doPostCall('user_language', params).subscribe(res => {
    }, error => {
      console.error(error);
    });

  }

}
