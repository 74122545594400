import { PB_DIRECTION, POSITION, SPINNER } from "ngx-ui-loader";

export class GLOBAL {

    //EndPoint
    public static API_REST_IP = "https://int.varianza.com/api/";
    //Encryption key
    public static SECRET_KEY = "sb.digital-Varianza";

    //AutoLoGoutService
    public static MINUTES_UNITL_AUTO_LOGOUT = 1; // in Minutes
    public static CHECK_INTERVALL = 1000; // in ms
    public static STORE_KEY = 'lastAction';

    //Messages - The messages are in the en/es .json. In the error group
    public static GENNERAL_ERROR = "message.general";
    public static ERROR_CREDENTIAL_LOGIN = "message.credentialError";
    public static USERNAME_BLANK = "message.usernameBlank";
    public static PASSWORD_BLANK = "message.passwordBlank";

    //Date Filter Message
    public static DATE_FILTER_MESSAGE_NO_SELECTED = "message.dateFilterError";
    public static BAD_DATES = "message.dateFromDateToError";
    public static MIN_DATE="message.dateMinError";
    public static MAX_DATE="message.dateMaxError";

    // Depeneds of status code
    public static BAD_REQUEST = "message.statusCode.badRequest";
    public static UNAUTHORIZED = "message.statusCode.unauthorized";
    public static FORBIDDEN = "message.statusCode.forbidden";
    public static NOT_FOUND = "message.statusCode.notFound";
    public static CONFLICT = "message.statusCode.conflict";
    public static INTERNAL_SERVER_ERROR = "message.statusCode.internalServerError";
    public static SERVICE_UNAVAILABLE = "message.statusCode.serviceUnavailable";
    public static EXCEED_UPLOAD_MAX_SIZE = "message.statusCode.uploadMaxSizeError";

    // Local notification service
    public static LOCAL_NOTIFICATION_CHECK_INTERVAL = 3600000;

    //Proposal Message
    public static APPROVED_PROPOSAL = "message.approvedProposal";
    public static REJECTED_PROPOSAL = "message.rejectedProposal";

    //Upload Document Message
    public static UPLOAD_DOCUMENT = "message.uploadDocument";
    public static ERROR_UPLOAD_DOCUMENT = "message.errorUploadDocument";

    // Aplication language array
    public static LANGUAGE_OPTIONS = [
        {
            'id': 'es',
            'name': 'ES',
        },
        {
            'id': 'en',
            'name': 'EN',
        }
    ];

    //Colors to messages
    public static GREEN_MESSAGE = "#A4E3C5" //green
    public static GREEN_LETTER_MESSAGE = "#1B7F4F"//other green
    public static RED_MESSAGE = "#EE5B6A"; //red
    public static RED_LETTER_MESSAGE = "#FFFFFF";//white
    
    //Colors to set to charts of Distributions
    public static DISTRIBUTION_CHARTS_COLORS = [
        '#3D5C66', '#377E96',
        '#55A3BF', '#BAD8EA',
        '#E5EEF5', '#86BED6',
        '#2E687B', '#46869D',
        '#A7CFE1', '#DDEDF6',
        '#2D434B', '#437688',
        '#91C3D9', '#D6E9F5',
        '#73B3CA', '#C6DFED'
    ];
    
    public static DISTRIBUTION_CHARTS_COLORS_LIGHT_VARIANT = [
        '#3D5C66', '#377E96', '#85BBCD', '#B5D8E4', '#DAEBF1', '#F0F7F9'
    ];

    public static DISTRIBUTION_CHARTS_COLORS_DARK_VARIANT = [
        '#F0F7F9', '#DAEBF1', '#B5D8E4', '#85BBCD', '#377E96', '#3D5C66'
    ];

    //Define Upload Max Size.
    public static MAX_UPLOAD_SIZE = 10;

        //NgxLoader Ui configuration
        public static NGX_LOADER_UI__CONFIGURATION = {
            bgsColor: "red",
            bgsOpacity: 0.5,
            bgsPosition: POSITION.centerCenter,
            bgsSize: 60,
            bgsType: SPINNER.ballSpinClockwise,
            blur: 5,
            fgsColor: "#bad8ea",
            fgsPosition: POSITION.centerCenter,
            fgsSize: 60,
            fgsType: SPINNER.squareLoader,
            gap: 24,
            logoPosition: POSITION.centerCenter,
            logoSize: 200,
            logoUrl: "",
            masterLoaderId: "master",
            overlayBorderRadius: "0",
            overlayColor: "rgba(40,40,40,0.86)",
            pbColor: "red",
            pbDirection: PB_DIRECTION.leftToRight,
            pbThickness: 3,
            hasProgressBar: false,
            text: "",
            textColor: "#FFFFFF",
            textPosition: POSITION.centerCenter,
        }
    
}