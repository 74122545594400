import { Permission } from '../permission/permission';

export class User {
    private _user: string;
    private _name: string;
    private _password: string;
    private _token: string;
    private _lang: string;
    private _hash: string;
    private _type: string;
    private _minDate: string;
    private _maxDate: string;
    private _permissions: Permission[];

    constructor(username: string, name: string, password: string, token: string, type: string, minDate: string, maxDate: string, hash?: string, lang?: string, permission?: Permission[]) {

        this._user = username;
        this._name = name;
        this._password = password;
        this._token = token;
        this._type = type;
        this._minDate = minDate;
        this._maxDate = maxDate;
        this._permissions = permission;
        this._hash = hash;
        this._lang = (lang) ? lang : 'es';

    }

    /**
     *
     * @returns {string}
     */
    public get name() {
        return this._name;
    }

    /**
     *
     * @param {string} name
     */
    public set name(name: string) {
        this._name = name;
    }

    /**
     *
     * @returns {string}
     */
    public get user() {
        return this._user;
    }

    /**
     *
     * @param {string} username
     */
    public set user(username: string) {
        this._user = username;
    }

    /**
     *
     * @returns {string}
     */
    public get password() {
        return this._password;
    }

    /**
     *
     * @param {string} password
     */
    public set password(password: string) {
        this._password = password;
    }

    /**
     *
     * @returns {string}
     */
    public get token() {
        return this._token;
    }

    /**
     *
     * @param {string} token
     */
    public set token(token: string) {
        this._token = token;
    }

    /**
     *
     * @returns {string}
     */
    public get hash() {
        return this._hash;
    }

    /**
     *
     * @param {string} username
     */
    public set hash(hash: string) {
        this._hash = hash;
    }

    /**
     *
     * @returns {string}
     */
    public get lang() {
        return this._lang;
    }

    /**
     *
     * @param {string} lang
     */
    public set lang(lang: string) {
        this._lang = lang;
    }

    /**
    *
    * @returns {Permission[]}
    */
    public get permissions() {
        return this._permissions;
    }

    /**
     *
     * @param {Permission[]} permissions
     */
    public set permissions(permissions: Permission[]) {
        this._permissions = permissions;
    }

    /**
    *
    * @returns {string}
    */
    public get type() {
        return this._type;
    }

    /**
     *
     * @param {string} type
     */
    public set type(type: string) {
        this._type = type;
    }
    /**
 *
 * @returns {string}
 */
    public get minDate() {
        return this._minDate;
    }

    /**
     *
     * @param {string} username
     */
    public set minDate(minDate: string) {
        this._minDate = minDate;
    }

    /**
     *
     * @returns {string}
     */
    public get maxDate() {
        return this._maxDate;
    }

    /**
     *
     * @param {string} username
     */
    public set maxDate(maxDate: string) {
        this._maxDate = maxDate;
    }

}
