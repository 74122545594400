import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { DateService } from "../date/date.service";
import { Platform } from '@ionic/angular';
import { DownloadService } from '../../download-service/download.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor(private dateservice: DateService, private platform: Platform, private downloadService: DownloadService) {
    }

    generateExcel(header: any, data: any, name: string) {

        //Excel Title, Header, Data
        const title = '  VARIANZA';


        //Create workbook and worksheet
        const workbook = new Workbook();
        let worksheet = workbook.addWorksheet(name);

        //Add Title Row and his formatting
        let titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Arial', size: 20, bold: true, color: { argb: 'FFFFFFFF' } };
        titleRow.eachCell((cell, number) => {

            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF2D3336' },
                bgColor: { argb: 'FF2D3336' },
            };

            cell.alignment = { vertical: 'middle' };

        });

        worksheet.mergeCells(`A${titleRow.number}:Z${titleRow.number}`);

        //Blank Row
        worksheet.addRow([]);

        //Add Header Row and formating
        let headerRow = worksheet.addRow(header);
        headerRow.font = { name: 'Arial', size: 12, bold: true, color: { argb: 'FF2D3336' } };
        headerRow.eachCell((cell, number) => {

            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFBAD8EA' },
                bgColor: { argb: 'FFBAD8EA' },
            };

            cell.alignment = { vertical: 'middle' };

        });

        // Add data to Cells.
        //worksheet.addRows(data);
        data.forEach(d => {
            let row = worksheet.addRow(d);
            row.font = { name: 'Arial', size: 12, color: { argb: 'FF2D3336' } };
        });

        // Change column with.
        let i = 1;
        for (i; i <= worksheet.columnCount; i++) {

            if (i === 1) worksheet.getColumn(i).width = 2;
            else worksheet.getColumn(i).width = 30;

        }

        // Change row eight.
        let j = 1;
        for (j; j <= worksheet.rowCount; j++) {

            if (j === 1) worksheet.getRow(j).height = 30;
            else worksheet.getRow(j).height = 15;

        }

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            if (this.platform.is('ios') || this.platform.is('android')) {

                let extraName = this.downloadService.makeid(5);
                this.downloadService.downloadAndShareFile(blob, name + '-' + this.dateservice.currentDate + " - " + extraName + '.xlsx',"","","");
           
            } else {
                fs.saveAs(blob, name.toLowerCase() + '-' + this.dateservice.currentDate + '.xlsx');
            }
        })
    }


}
