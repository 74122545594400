import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SessionDataService } from '../providers/sessionData/session-data.service';
import { MyPortfolioService } from '../providers/my-portfolio/my-portfolio/my-portfolio.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  scroll: boolean;

  constructor(private router: Router, private sessionDataService: SessionDataService, private myPortfolioService: MyPortfolioService) {
    this.scroll = false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.myPortfolioService.newStatus(this.scroll);
    this.scroll = !this.scroll;

    let currentUser = this.sessionDataService.getUser();
    //  console.debug("El usuario del storage es: o%",currentUser)
    if (currentUser !== null) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }

}
