export class Language {

    private _id: string;
    private _lang: string;

    constructor(id: string, lang: string) {

        this._id = id;
        this._lang = lang;

    }

    /**
     *
     * @returns {string}
     */
    public get id() {
        return this._id;
    }

    /**
     *
     * @param {string} id
     */
    public set id(id: string) {
        this._id = id;
    }

    /**
     *
     * @returns {string}
     */
    public get lang() {
        return this._lang;
    }

    /**
     *
     * @param {string} lang
     */
    public set lang(lang: string) {
        this._lang = lang;
    }
}
