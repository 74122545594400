import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    private routeSelected = new BehaviorSubject<string>('dashboard');
    public routeSelected$ = this.routeSelected.asObservable();
    private navbarActive = new BehaviorSubject<boolean>(false);
    public navbarActive$ = this.navbarActive.asObservable();

    constructor(private router: Router) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                let valueUrl = "";
                valueUrl = this.checkUrl(val.urlAfterRedirects);
                this.routeSelected.next(valueUrl);
            }
        });
    }

    navbarActiveEmmit(show:boolean){
        this.navbarActive.next(show);
    }

    checkUrl(rute: any): string {

        if (rute.includes('/my-portfolio')) {
            return 'my-portfolio';
        } else if (rute.includes('/my-documents')) {
            return 'my-documents';
        } else if (rute.includes('/dashboard')) {
            return 'dashboard';
        } else if (rute.includes('/my-documents')) {
            return 'my-portfolio';
        } else if (rute.includes('/register-dashboard')) {
            return 'registerDashboard';
        } else if (rute.includes('/register-process')) {
            return 'registerDashboard';
        } else if (rute.includes('/register-documents')) {
            return 'registerDashboard';
        } else if (rute.includes('/register-final')) {
            return 'registerDashboard';
        } else if (rute.includes('/register')) { //TODO revisar cuando se añada la nueva ruta
            return 'clientRegistration';
        } else if (rute.includes('/help')) {
            return 'help';
        } else if (rute.includes('/maintenance')) {
            return 'maintenance';
        }

    }
}
