import { Injectable } from '@angular/core';
import { Message } from 'src/app/models/message/message';
import { GLOBAL } from '../../global/global';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  rowMapper(statusCode: string, text: string, color: string): Message {

    let message = new Message(
      statusCode,
      (text !== null) ? text : "",
      (color !== null) ? color : "#",
    )

    return message;
  }

  /**
   * Return a arrayList with Messages
   * @param error The error message we have received from the api rest
   * @returns Message[] A Collection with all messages from api rest
   */
  setMessagesWithTypeError(error: any): Message[] {

    let messages = [];
    let messageText = "";
    Object.entries(error.error).forEach(v => {

      switch (v[0]) {
        case 'non_field_errors':
          messageText = GLOBAL.ERROR_CREDENTIAL_LOGIN;
          break;
        case 'username':
          messageText = GLOBAL.USERNAME_BLANK;
          break;
        case 'password':
          messageText = GLOBAL.PASSWORD_BLANK;
          break;

        default:
          messageText = GLOBAL.GENNERAL_ERROR;
          break;
      }

      if (!messages.find(item => item.statusCode === error.status)) messages.push(this.rowMapper(error.status, messageText, GLOBAL.RED_MESSAGE));

    });

    return messages;
  }

  setMessage(statusCode, messageText, color): Message[] {

    let messages = [];
    messages.push(this.rowMapper(statusCode, messageText, color));
    return messages;
  
  }

  setMessagesWithStatusCode(error: any): Message[] {

    let messages = [];
    let messageText = "";

    switch (error.status) {
      case 400:
        messageText = GLOBAL.BAD_REQUEST;
        break;
      case 401:
        messageText = GLOBAL.UNAUTHORIZED;
        break;
      case 403:
        messageText = GLOBAL.FORBIDDEN;
        break;
      case 404:
        messageText = GLOBAL.NOT_FOUND;
        break;
      case 409:
        messageText = GLOBAL.CONFLICT;
        break;
      case 500:
        messageText = GLOBAL.INTERNAL_SERVER_ERROR;
        break;
      case 503:
        messageText = GLOBAL.SERVICE_UNAVAILABLE;
        break;
      default:
        messageText = GLOBAL.GENNERAL_ERROR;
        break;
    }

    if (!messages.find(item => item.statusCode === error.status)) messages.push(this.rowMapper(error.status, messageText, "#EE5B6A"));

    return messages;
  
  }

}
