import { Injectable } from '@angular/core';
import { SessionDataService } from '../../sessionData/session-data.service';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    currentDate: string;
    currentDateDFormat: Date;

    periodFromDate: string;
    periodCurrentDate: string;

    //Dates to limit inputs 
    minDateLimit: string;
    maxDateLimit: string;
    dateMaxLimit: Date;

    constructor(private sessionService: SessionDataService, private datePipe: DatePipe, private sessionDate: SessionDataService) {

        this.periodCurrentDate = "";
        this.periodCurrentDate = "";
        this.generateAndFormateDate();

        this.sessionDate.filtersValues.subscribe(v => {

            this.setMinMaxDate(v.global_start_date, v.global_end_date);
        })


    }

    /**
     * Method to reduce the dates, you will send the year, month and day you want to subtract
     * @param year
     * @param month
     * @param day
     */
    getReducedDateOfTheCurrentDate(year: number, month: number): Date {

        let yearCheck = this.dateMaxLimit.getFullYear() - year;
        let monthCheck = (this.dateMaxLimit.getMonth()) - month;
        let dayCheck = this.dateMaxLimit.getDate() - 1;

        if (monthCheck === 0) {

            yearCheck = yearCheck - 1;
            monthCheck = 12;
            dayCheck = 31;

        }

        if (dayCheck === 31) {

            if (monthCheck === 1 || monthCheck === 3 || monthCheck === 5 || monthCheck === 7 || monthCheck === 8 || monthCheck === 10 || monthCheck === 12) {
                dayCheck = 31;
            }

            if (monthCheck === 4 || monthCheck === 6 || monthCheck === 9 || monthCheck === 11) {
                dayCheck = 30;
            }
        }

        if (dayCheck === 29 && monthCheck === 2 && !this.leapYear(yearCheck)) {
            dayCheck = 28;
        }

        return new Date(yearCheck, monthCheck, dayCheck);
    }

    leapYear(year) {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    getYTD() {
        return new Date((this.dateMaxLimit.getFullYear() - 1), 11, 31);
    }

    generateAndFormateDate() {

        let date = new Date;
        this.currentDateDFormat = date;
        this.currentDate = this.transformDate(date);

    }

    transformDate(date: Date) {

        let language = localStorage.getItem('language');
        // let formatDate = 'dd/MM/yyyy';
        //if (language === 'en') {
        let formatDate = 'yyyy-MM-dd';
        // }
        let currentDate = this.datePipe.transform(date, formatDate);
        return currentDate;

    }

    getDatePeriod(date_from: string, date_to: string, max_date_from: string, max_date_to: string) {

        let user = this.sessionService.getUser();
        this.periodFromDate = user.minDate;
        this.periodCurrentDate = this.maxDateLimit;

        if ((date_from !== "" || date_from !== undefined) && (date_to !== "" || date_to !== undefined)) {
            this.periodCurrentDate = date_to;
            this.periodFromDate = date_from;
        }

        if ((date_from === "" || date_from === undefined) && (date_to == "" || date_to !== undefined)) {
            this.periodCurrentDate = max_date_to;
            this.periodFromDate = max_date_from;
        }

        return {

            'periodFromDate': this.datePipe.transform(this.periodFromDate, 'dd/MM/yyyy'),
            'periodCurrentDate': this.datePipe.transform(this.periodCurrentDate, 'dd/MM/yyyy'),

        };

    }

    setMinMaxDate(init_date: string, end_date: string) {

        if (init_date === "" && end_date === "") {

            let user = this.sessionService.getUser();
            this.minDateLimit = user.minDate;
            this.maxDateLimit = user.maxDate;
            this.dateMaxLimit = new Date(this.maxDateLimit);
        } else {
            this.minDateLimit = init_date;
            this.maxDateLimit = end_date;
            this.dateMaxLimit = new Date(this.maxDateLimit);
        }


    }
}
