export class Notification {

    private _id: number;
    private _message: string;
    private _date: string;
    private _url: string;
    private _type: string;
    private _status: boolean;

    constructor(id: number, message: string, date: string, url: string, type: string, status: boolean) {

        this._id = id;
        this._message = message;
        this._date = date;
        this._url = url;
        this._type = type;
        this._status = status

    }

    /**
     *
     * @returns {number}
     */
    public get id() {
        return this._id;
    }

    /**
     *
     * @param {number} id
     */
    public set id(id: number) {
        this._id = id;
    }

    /**
     *
     * @returns {string}
     */
    public get message() {
        return this._message;
    }

    /**
     *
     * @param {string} message
     */
    public set message(message: string) {
        this._message = message;
    }

    /**
     *
     * @returns {string}
     */
    public get date() {
        return this._date;
    }

    /**
     *
     * @param {string} date
     */
    public set date(date: string) {
        this._date = date;
    }

    /**
     *
     * @returns {string}
     */
    public get url() {
        return this._url;
    }

    /**
     *
     * @param {string} url
     */
    public set url(url: string) {
        this._url = url;
    }

    /**
     *
     * @returns {string}
     */
    public get type() {
        return this._type;
    }

    /**
     *
     * @param {string} type
     */
    public set type(type: string) {
        this._type = type;
    }

    /**
     *
     * @returns {boolean}
     */
    public get status() {
        return this._status;
    }

    /**
     *
     * @param {boolean} status
     */
    public set status(status: boolean) {
        this._status = status;
    }

}
