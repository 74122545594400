import { Injectable } from '@angular/core';
import { ApiclientService } from '../../apiclient/apiclient.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiClient: ApiclientService) { }

  /**
 * Create Token into Varianza API.
 * @param {string} username
 * @param {string} password
 * @returns {Observable<any>}
 */
  postToken(username: string, password: string): Observable<any> {

    return this.apiClient.doPostLogin(username, password);
  }
}
