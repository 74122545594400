import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyPortfolioService {

  private scrollActive = new Subject<boolean>();

  constructor() {
  }

  public newStatus(value: boolean) {
    this.scrollActive.next(value)

  }

  public get scrollStatus(): Observable<boolean> {
    return this.scrollActive.asObservable();
  }

}
