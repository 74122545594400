import { Injectable } from '@angular/core';
import { Platform, ToastController, ActionSheetController } from '@ionic/angular';
import { DateService } from '../partials/date/date.service';
import { File } from '@ionic-native/file/ngx';
import { TranslateService } from '@ngx-translate/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DocumentViewerService } from '../document-viewer-service/document-viewer.service';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { GetDocumentService } from '../partials/document/get-document.service';
import { NotificationsService } from '../partials/document/notifications.service';
import { Document } from 'src/app/models/document/document';
import { FileOpener2Service } from '../file-opener-2/file-opener-2.service';
import { Proposal } from 'src/app/models/document/proposal';
import { LoaderService } from '../partials/loader-service/loader.service';


@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    path: string;
    messageToShow: string;
    errorMessage: string;

    constructor(private dateservice: DateService, private platform: Platform,
        private file: File, public toastController: ToastController,
        private translate: TranslateService, private socialSharing: SocialSharing,
        private documentViewerService: DocumentViewerService, private fileOpenerService: FileOpener2Service,
        private photoViewer: PhotoViewer, private getDocumentService: GetDocumentService, private dateService: DateService,
        private translateService: TranslateService, public actionSheetController: ActionSheetController,
        private notificationService: NotificationsService, private loaderService: LoaderService) {
        this.checkPlatform();
    }


    checkPlatform() {
        if (this.platform.is("ios")) {

            this.path = `${this.file.dataDirectory}`;
            this.messageToShow = this.translate.instant('downloads.downloadIos');
            this.errorMessage = this.translate.instant('downloads.errorIos');
        } else {
            this.path = `${this.file.externalRootDirectory + 'download/'}`;
            this.messageToShow = this.translate.instant('downloads.downloadAndroid');
            this.errorMessage = this.translate.instant('downloads.errorAndroid');
        }
    }

    /**
     * Method to get a file from a blob,
     * @param fileToDownload 
     * @param name 
     * @param contentType 
     */
    downloadFileAndOpenDocumentViewer(fileToDownload: Blob, name: any, contentType: string, docType: string, from: string, document: any, proposal?: Proposal) {
        let path = this.path;
        if (this.platform.is('android')) { path = `${this.file.externalCacheDirectory}`; }

        this.file.writeFile(path, name, fileToDownload).then(result => {

            if (this.platform.is('android')) {
                this.loaderService.stopLoader();
                this.fileOpenerService.viewDocument(result.nativeURL, contentType, docType, from, document, proposal);
            } else {
                this.loaderService.stopLoader();
                this.documentViewerService.viewDocument(result.nativeURL, name, contentType, docType, from, document, proposal);
            }

        }).catch(error => {

            this.loaderService.stopLoader();
            this.presentToast(this.errorMessage);
            console.error(error);
        })

    }

    downloadFileAndOpenPhotoViewer(fileToDownload: Blob, name: any, docType: string, from: string, document: any, proposal?: Proposal) {
        const options = {
            share: true, // default is false
            closeButton: true, // default is true
            copyToReference: true, // default is false
            headers: "",  // If it is not provided, it will trigger an exception
            piccasoOptions: {} // If it is not provided, it will trigger an exception
        };
        this.file.writeFile(this.path, name, fileToDownload).then(result => {

            this.loaderService.stopLoader();

            this.photoViewer.show(result.nativeURL, name, options);

            if ((docType !== 'legal') && (document.statusClass === "approved") && from === 'list') {
                this.notificationService.reduceNotification(docType);

                document.status = this.translateService.instant("statusDocuments.read");
                document.statusClass = "read";
            }

            if (from === "proposal" && document.viewed === false) {
                document.viewed = true;
                //  this.totalDocumentViewed = this.countTotalDocumentViewed();
            }
        }).catch(error => {
            this.presentToast(this.errorMessage);
            console.error(error)
        })
    }

    downloadAndShareFile(fileToDownload: Blob, name: any, docType: string, from: string, document: any, proposal?: Proposal) {
        this.file.writeFile(this.path, name, fileToDownload).then(result => {
            if (this.platform.is('ios')) {
                this.loaderService.stopLoader();
                this.shareFiles(result.nativeURL, docType, from, document, proposal);
            }

            if (this.platform.is('android')) {


                if ((docType !== 'legal') && (document.statusClass === "approved") && from === 'list') {
                    this.notificationService.reduceNotification(docType);
                    document.status = this.translateService.instant("statusDocuments.read");
                    document.statusClass = "read";
                }
                if (from === "proposal" && document.viewed === false) {
                    document.viewed = true;
                    proposal.updateReadDocuments();
                }
                this.loaderService.stopLoader();

                this.presentToast(this.messageToShow);
            }
        }).catch(error => {

            this.loaderService.stopLoader();
            this.presentToast(this.errorMessage);
            console.error(error);
        })
    }

    /**
     * Method to share and download the file to save in the mobile
     * @param urlFile 
     */
    shareFiles(urlFile: string, docType: string, from: string, document: any, proposal?: Proposal) {
        let options = {
            url: urlFile
        }

        this.socialSharing.shareWithOptions(options).then(result => {

            if (result.completed === true) {

                if ((docType !== 'legal') && (document.statusClass === "approved") && from === 'list') {
                    this.notificationService.reduceNotification(docType);
                    document.status = this.translateService.instant("statusDocuments.read");
                    document.statusClass = "read";
                }
                if (from === "proposal" && document.viewed === false) {
                    document.viewed = true;
                    proposal.updateReadDocuments();
                }
                this.presentToast(this.messageToShow);
            }

        }).catch(error => {
            this.presentToast(this.errorMessage);
            console.error(error);
        });
    }

    async presentToast(textToShow: string) {

        const toast = await this.toastController.create({
            message: textToShow,
            duration: 2000
        });

        toast.present();

    }


    makeid(length) {

        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
       
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;

    }

    normalizeString(text: string): string {

        let textNormalized = text.replace(/ /g, "");
        textNormalized = textNormalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return textNormalized;

    }

    //----------------------------------------------------------------//
    // Lógica de preview pdf //

    async selectViewOrDownload(docType: string, from: string, document: Document, documen_id?: any) {

        const actionSheetViewDownload = await this.actionSheetController.create({

            header: this.translateService.instant('previews.options'),
            buttons: [
                {
                    text: this.translateService.instant('previews.viewFile'),
                    handler: () => {

                        let id = (documen_id !== undefined && documen_id !== null) ? documen_id : document.id;
                        this.getDocumentView(docType, from, document, id);
                        this.loaderService.startLoader();
                    }
                },
                {
                    text: this.translateService.instant('previews.downloadFile'),
                    handler: () => {
                        this.getDocumentDownload(docType, from, document);
                        this.loaderService.startLoader();
                    }
                },
                {
                    text: this.translateService.instant('myDocuments.cancelOption'),
                    role: 'cancel',
                }
            ]
        });

        await actionSheetViewDownload.present();
    }


    getDocumentDownload(docType: string, from: string, document: Document, documen_id?: any) {

        let params = { 'document_id': (documen_id !== undefined && documen_id !== null) ? documen_id : document.id };
        this.getDocumentService.getDocument(params).subscribe(res => {
            
            let type = ".pdf";
            
            if (res.type !== "application/pdf") {
                type = ".jpeg";
            }
            
            let extraName = this.makeid(5);
            let text = this.normalizeString(document.name);
            this.downloadAndShareFile(res, text + '-' + this.dateservice.currentDate + '-' + extraName + type, docType, from, document);

        }, error => {
            console.error(error);
        });

    }

    getDocumentView(docType: string, from: string, document: any, documen_id?: any) {

        let params = { 'document_id': (documen_id !== undefined && documen_id !== null) ? documen_id : document.id };

        this.getDocumentService.getDocument(params).subscribe(res => {

            if (res.type !== "application/pdf") {

                let extraName = this.makeid(5);
                let text = this.normalizeString(document.name);
                this.downloadFileAndOpenPhotoViewer(res, text + '-' + this.dateService.currentDate + '-' + extraName, docType, from, document);

            }
            if (res.type === "application/pdf") {

                let extraName = this.makeid(5);
                this.downloadFileAndOpenDocumentViewer(res, document.name + '-' + this.dateService.currentDate + '-' + extraName + '.pdf', "application/pdf", docType, from, document);

            }
            return document;

        }, error => {

            this.presentToast("No se ha podido mostrar el fichero");
            console.error(error);

        });

    }

}
