import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { ApiclientService } from '../apiclient/apiclient.service';
import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/operators';
import { SessionDataService } from '../sessionData/session-data.service';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(private firebase: FirebaseMessaging,
    private platform: Platform,
    private device: Device,
    private apiClient: ApiclientService,
    private sessionDataService: SessionDataService,
    public toastController: ToastController
  ) {
    this.listenMessages();
  }



  initializePushCheck() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.platform.ready().then(() => {

        // Before push registration, check if user is logged
        this.sessionDataService.isLoggedIn.pipe(
          filter(loggedIn => loggedIn),
          tap(() => {
            // Ask permissions request to the user
            this.firebase.requestPermission().then((permission) => {
              console.log(` --> Permission: ${permission}`)
              // Generate Firebae Token
              this.firebase.getToken()
                .then((token) => {
                  console.log(` --> Push Token ${token}`)
                  // Save data
                  this.updatePushData(token);            
                })
                .catch(error => console.error(error));
            });
          })
        ).subscribe();
      }).catch((error) => console.log(error))
    }
  }


  private listenMessages() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.platform.ready().then(() => {
        /*
        {
          "gcm":{
            "body":"Test notificación push Test notificación push Test notificación push",
            "title":"Test notificación push",
            "tag":"campaign_collapse_key_7849184613776213438"
          },
          "google.message_id":"0:1683643726682673%7c7ed1a87c7ed1a8",
          "google.sent_time":1683643726673
        }
        */

        this.firebase.onMessage().pipe(
          tap(() => console.log(' --> Mensaje FOREGROUND recibido:')),
          tap((message) => console.log(JSON.stringify(message))),
          switchMap((message) => 
            from(this.toastController.create({
              message: message?.gcm?.body,
              duration: 2000,
              position: 'top',
            }))
          ),
          tap(toast => toast?.present()),
        ).subscribe();

        this.firebase.onBackgroundMessage().pipe(
          tap(() => console.log(' --> Mensaje BACKGROUND recibido:')),
          tap((message) => console.log(JSON.stringify(message)))
        ).subscribe();

        this.firebase.onTokenRefresh().pipe(
          tap(() => console.log(' --> Refresh Token:')),
          tap(() => this.initializePushCheck())
        ).subscribe();

      });
    }
  }









  /**
   * Store/Update user push data on the server. 
   * @param {string} token 
   */
  private updatePushData(token: string): void {
    this.platform.ready().then(() => {
      const deviceInfo = {
        platform: this.device.platform,
        uuid: this.device.uuid,
        model: this.device.model,
        manufacturer: this.device.manufacturer,
        verison: this.device.version,
        token
      };

      const params = {
        registration_token: token,
        device_uuid: this.device.uuid,
        os: this.device.platform
      };

      // Realizamos la petición de registro de dispositivo para futuros mensajes push.
      this.apiClient.doPostCall('register_device', params).pipe(
        delay(2000),
        take(1),
        tap(response => {

          console.log(` --> Dispositivo registrado! `)
          console.log(JSON.stringify(response))
        }),
        catchError(error => {
          console.log(error);
          return of(error);

        })
      ).subscribe();

      console.log(JSON.stringify(deviceInfo));
    });

  }

}
