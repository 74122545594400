import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from  'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private loaderService: NgxUiLoaderService) { }

  startLoader(taskId?:string){
    this.loaderService.start(taskId);
  }

  stopLoader(taskId?:string){
      this.loaderService.stop(taskId);
  }
}
