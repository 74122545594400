import { Injectable } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NotificationsService } from '../partials/document/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Proposal } from 'src/app/models/document/proposal';

@Injectable({
  providedIn: 'root'
})
export class FileOpener2Service {

  constructor(private fileOpener: FileOpener, private notificationService: NotificationsService, private translateService: TranslateService) { }


  viewDocument(nativeUrl: string, type: string, docType: string, from: string, document: any,proposal?:Proposal) {
    this.fileOpener.open(nativeUrl, type).then(v => {

      if ((document.statusClass === "approved") && from === 'list') {
        this.notificationService.reduceNotification(docType);

        document.status = this.translateService.instant("statusDocuments.read");
        document.statusClass = "read";
      }

      if (from === "proposal" && document.viewed === false) {
        document.viewed = true;
        proposal.updateReadDocuments();
      }
      
    }, error => {
      console.error(error);
    });
  }
}
