import { Injectable } from '@angular/core';
import { GLOBAL } from '../../global/global';
import * as CryptoJS from 'crypto-js';
import { User } from 'src/app/models/user/user';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Permission } from 'src/app/models/permission/permission';
import { Filter } from 'src/app/models/filter/filter';
import { Budget } from 'src/app/models/budget/budget';


const SECRET_KEY = GLOBAL.SECRET_KEY;

@Injectable({
    providedIn: 'root'
})
export class SessionDataService {

    private filtersValuesSource = new BehaviorSubject<Filter>(new Filter("", null, "", "", "", ""));
    public filtersValues = this.filtersValuesSource.asObservable();

    private userLogged: User;
    private loggedIn = new BehaviorSubject<boolean>(false);
    //Variable with the filters
    private currentFilter: Filter

    constructor(private router: Router, private storage: Storage) {

        this.currentFilter = this.filtersValuesSource.value;
        this.userLogged = null;
        this.loggedIn.next(this.checkSessionStorage())

    }

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    checkSessionStorage() {

        let user = this.getUser();
        if (user === null) {
            return false;
        } else {
            return true;
        }

    }
    /**
     * Create new User Obj.
     * @param {string} username
     * @param {string} token
     * @param {string} lang
     * @param {boolean} isLogged
     * @returns {User}
     */
    newUser(username: string, name: string, password: string, token: string, type: string, minDate: string, maxDate: string, hash?: string, lang?: string, permissions?: Permission[]): User {

        let user = new User(username, name, password, token, type, minDate, maxDate, (hash) ? hash : '', (lang) ? lang : 'es', (permissions) ? permissions : []);
        return user;

    }

    /**
     * This method save the user in the storage and session-data service
     * @param {User} user
     */
    saveUser(user: User) {

        let sessionData = {};
        this.userLogged = user;

        let storedUser = new User(this.encryptToken(user.user), user.name, this.encryptToken(user.password), this.encryptToken(user.token), user.type, user.minDate, user.maxDate, user.hash, user.lang, user.permissions);
        sessionData = { "currentUser": storedUser }
        this.storage.set('sessionData', sessionData);

    }

    logIn() {
        this.loggedIn.next(true);
    }

    logOut() {
        this.cleanFilterObject();
        this.userLogged = null;
        this.loggedIn.next(false);
        this.deleteItemFromStorage('sessionData');
        this.deleteItem('lastAction');
        this.router.navigate(['']);

    }

    cleanFilterObject() {
        this.currentFilter = new Filter("", null, "", "", "", "");
        this.filtersValuesSource.next(this.currentFilter);

    }

    /**
     *This method return the logged user
     * @returns {User}
     */
    getUser() {

        if (this.userLogged !== null) {
            return this.userLogged;
        } else {

            let user = null;
            let sessionData = this.getItemFromStorage('sessionData');

            if (sessionData === undefined) {
                return null;
            }

            user = new User(sessionData.currentUser._user, sessionData.currentUser._name, sessionData.currentUser._password, sessionData.currentUser._token, sessionData.currentUser._type, sessionData.currentUser._hash, sessionData.currentUser._lang, sessionData.currentUser._permissions);
            user.user = this.decryptToken(user.user);
            user.token = this.decryptToken(user.token);
            user.password = this.decryptToken(user.password);

            //Save the user in the sessionStorage
            this.saveUser(user);
            this.userLogged = user;

            return this.userLogged;

        }

    }

    checkUserPermission(permissionId): boolean {

        let currentUser = this.getUser();
        if (currentUser !== null && currentUser.permissions !== undefined) {

            let proposalPermission = currentUser.permissions.find(val => val.id === permissionId);

            if (proposalPermission !== undefined && proposalPermission !== null && proposalPermission.permission === true) {
                return true
            }

        }

        return false;
    }

    getCurrentFilter() {
        return this.currentFilter;
    }

    changeSessionLang(newLng: string) {

        // this.userLogged;
        let user = this.userLogged
        user.lang = newLng
        this.saveUser(user);

    }

    setCurrentBudgetFilter(paramsToSave: any) {

        let valuesOfSessionStorage = null;
        valuesOfSessionStorage = this.getCurrentFilter();


        if (paramsToSave.id !== undefined) valuesOfSessionStorage = this.checkTheNewIdParam(paramsToSave, valuesOfSessionStorage);
        if (paramsToSave.date_from !== undefined && paramsToSave.date_to !== undefined) valuesOfSessionStorage = this.checkTheNewDatesParams(paramsToSave, valuesOfSessionStorage);

        this.setFiltersValuesSource(valuesOfSessionStorage);
    }

    setFiltersValuesSource(newFilter: Filter) {
        this.currentFilter = newFilter;
        this.filtersValuesSource.next(newFilter);
    }

    setCurrentDatesToFilter(parmsToSave: any) {

        if (parmsToSave !== undefined && parmsToSave !== null) {

            this.filtersValuesSource.next(Object.assign(this.filtersValuesSource.value, { init_date_selected: parmsToSave.date_from, end_date_selected: parmsToSave.date_to }));
            this.currentFilter = this.filtersValuesSource.value;
        }
    }

    setCurrentBudgetFilterArray(paramsToSave: Budget[]) {

        let valuesOfSessionStorage: Filter;
        valuesOfSessionStorage = this.getCurrentFilter();

        if (paramsToSave !== undefined && paramsToSave !== null) {

            this.filtersValuesSource.next(Object.assign(this.filtersValuesSource.value, { budget_selected: paramsToSave, init_date_selected: "", end_date_selected: "" }));
            this.currentFilter = this.filtersValuesSource.value;

        }
    }

    /**
     * This method checks the new id param to set into the sessionStorage
     * @param paramsToSave New Params to set
     * @param valuesOfSessionStorage The old Params
     * @returns valuesOfSessionStorage with new Params
     */
    private checkTheNewIdParam(paramsToSave: any, valuesOfSessionStorage: any) {

        (paramsToSave.id !== undefined && paramsToSave.id !== "")
            ? valuesOfSessionStorage.ids = paramsToSave.id
            : delete valuesOfSessionStorage.ids;

        return valuesOfSessionStorage;

    }

    /**
     * This method checks the new dates params to set into the sessionStorage
     * @param paramsToSave New Params to set
     * @param valuesOfSessionStorage The old Params
     * @returns valuesOfSessionStorage with new Params
     */
    private checkTheNewDatesParams(paramsToSave: any, valuesOfSessionStorage: any) {

        (paramsToSave.date_to !== undefined && paramsToSave.date_to !== "")
            ? valuesOfSessionStorage.end_date_selected = paramsToSave.date_to
            : delete valuesOfSessionStorage.end_date_selected;

        (paramsToSave.date_from !== undefined && paramsToSave.date_from !== "")
            ? valuesOfSessionStorage.init_date_selected = paramsToSave.date_from
            : delete valuesOfSessionStorage.init_date_selected;

        return valuesOfSessionStorage;

    }

    encryptToken(token: string): string {

        let conversionEncryptOutput = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
        token = conversionEncryptOutput;

        return token;

    }

    decryptToken(tokenEncrypted: string): string {

        let conversionDecryptOutput = CryptoJS.AES.decrypt(tokenEncrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        tokenEncrypted = conversionDecryptOutput;

        return tokenEncrypted;

    }

    deleteItemFromStorage(key: string) {
        this.storage.remove(key);
    }

    getItemFromStorage(key: string): any {

        this.storage.get(key).then(val => {
            return val;
        })

    }

    deleteItem(key: string) {
        sessionStorage.removeItem(key);
    }

    /**
   * This method generate the parameters to send to api query
   * @param filter 
   */
    generateParamsFromFilter(filter: Filter) {
        let result = {};

        if (filter !== null) {

            if (filter.ids !== "" && filter.ids !== null) {
                result = Object.assign({ id: filter.ids }, result);
            }

            if (filter.init_date_selected !== "" && filter.init_date_selected !== null) {
                result = Object.assign({ date_from: filter.init_date_selected }, result);
            }else{
                result=Object.assign({date_from:filter.global_start_date},result);
              }
        

            if (filter.end_date_selected !== "" && filter.end_date_selected !== null) {
                result = Object.assign({ date_to: filter.end_date_selected }, result);
            }else{
                result=Object.assign({date_to:filter.global_end_date},result);
              }
        
        }

        return result;
    }

    /**
     * This method generate the parameters to send to api query
     * @param filter
     */
    generateDocumentParamsFromFilter(filter:Filter) {
        let result;

        if (filter !== null) {
            if (filter.ids !== "" && filter.ids !== null) {
            result = Object.assign({ portfolios: filter.ids}, result);
            }
        }

        return result;
    }
}
