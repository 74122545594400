export class Message {
    private _statusCode: string;
    private _textToShow: string;
    private _color: string;

    constructor(statusCode: string, textToShow: string, color: string) {
        this._statusCode = statusCode;
        this._textToShow = textToShow;
        this._color = color;
    }

    public get statusCode() {
        return this._statusCode;
    }
    public set statusCode(statusCode: string) {
        this._statusCode = statusCode;
    }

    public get textToShow() {
        return this._textToShow;
    }
    public set textToShow(textToShow: string) {
        this._textToShow = textToShow;
    }

    public get color() {
        return this._color;
    }
    public set color(color: string) {
        this._color = color;
    }


}
