import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Unread } from 'src/app/models/notifications/unread';
import { ApiclientService } from '../../apiclient/apiclient.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private unreadNotifications = new BehaviorSubject<Unread>(new Unread(0, 0, 0, 0));
  public unreadNotificationObservable = this.unreadNotifications.asObservable();

  constructor(private apiClient: ApiclientService) {
  }

  /**
   * Call Get Unread notifications API Call.
   * @param params
   * @returns {Observable<any>}
   */
  getUnreadNotifications(params) {
    return this.apiClient.doGetCall('documents/notifications', params);
  }

  roadMapper(unreads: any): Unread {

    return new Unread(
      (unreads.proposal.unread !== undefined && unreads.proposal.unread !== null) ? this.unreadNotifications.value.proposals = unreads.proposal.unread : 0,
      (unreads.inform.unread !== undefined && unreads.inform.unread !== null) ? this.unreadNotifications.value.informs = unreads.inform.unread : 0,
      (unreads.legal.unread !== undefined && unreads.legal.unread !== null) ? this.unreadNotifications.value.legals = unreads.legal.unread : 0,
      (unreads.rto.unread !== undefined && unreads.rto.unread !== null) ? this.unreadNotifications.value.rto = unreads.rto.unread : 0,

    );

  }

  setUnreadNotifications(unreads: any) {

    let unreadObject = this.roadMapper(unreads);
    this.unreadNotifications.next(unreadObject);

  }

  reduceNotification(type: string) {

    switch (type) {

      case 'proposal': {
        this.unreadNotifications.value.proposals--;
        break;
      }

      case 'inform': {
        this.unreadNotifications.value.informs--;
        break;
      }

      case 'legal': {
        this.unreadNotifications.value.legals--;
        break;
      }

      case 'rto': {
        this.unreadNotifications.value.rto--;
        break;
      }

    }

    this.unreadNotifications.next(this.unreadNotifications.value);

  }

  incrementNotification(type: string) {

    switch (type) {

      case 'proposal': {
        this.unreadNotifications.value.proposals++;
        break;
      }

      case 'inform': {
        this.unreadNotifications.value.informs++;
        break;
      }

      case 'legal': {
        this.unreadNotifications.value.legals++;
        break;
      }

      case 'rto': {
        this.unreadNotifications.value.rto++;
        break;
      }
      
    }

    this.unreadNotifications.next(this.unreadNotifications.value);

  }

}
