//Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Device } from '@ionic-native/device/ngx';

//Services
import { ApiclientService } from './providers/apiclient/apiclient.service';
import { SessionDataService } from './providers/sessionData/session-data.service';
import { AuthService } from './providers/auth/login/auth.service';
import { LanguageService } from './providers/language/language.service';
import { MessageService } from './providers/message/message.service';
import { NavbarService } from './providers/partials/navbar/navbar.service';
import { ModalService } from './providers/partials/modal/modal.service';
import { NotificationsService } from './providers/dashboard/notifications/notifications.service';
import { DateService } from './providers/partials/date/date.service';
import { ClientService } from './providers/my-portfolio/client/client.service';
import { PortfolioService } from './providers/my-portfolio/portfolio/portfolio.service';
import { ExcelService } from './providers/partials/excel/excel.service';
import { FooterService } from './providers/partials/footer/footer.service';

//Register the locale of the app
import es from '@angular/common/locales/es';
//Global file 
import { GLOBAL } from './global/global';
//Pipe
import { registerLocaleData, DatePipe, DecimalPipe } from '@angular/common';
import { LocalNotificationService } from './providers/notification/local.notification.service';
import { PushNotificationService } from './providers/notification/push.notification.service';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(es);
const ngxUiLoaderConfig: NgxUiLoaderConfig = GLOBAL.NGX_LOADER_UI__CONFIGURATION;

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,// para poder usar doble binding o banana in a box
    HttpClientModule,//Para usarlo con los services OBLIGATORIO
    ReactiveFormsModule, //Para formularios reactivos
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    //ComponentsModule, //Where you declare the differents components
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FingerprintAIO,
    BackgroundMode,
    LocalNotifications,
    Device,
    FirebaseMessaging,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    Keyboard,
    File,
    FileOpener,
    SocialSharing,
    DocumentViewer,
    PhotoViewer,
    WebView,
    ApiclientService,
    LocalNotificationService,
    PushNotificationService,
    SessionDataService,
    AuthService,
    LanguageService,
    MessageService,
    NavbarService,
    ModalService,
    NotificationsService,
    DatePipe,
    DecimalPipe,
    DateService,
    ClientService,
    PortfolioService,
    ExcelService,
    FooterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
