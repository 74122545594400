import { Injectable } from '@angular/core';
import { GLOBAL } from '../../global/global';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionDataService } from '../sessionData/session-data.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Platform } from '@ionic/angular';
import { stringify } from 'querystring';
import { NotificationsService } from 'src/app/providers/dashboard/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  constructor(private localNotifications: LocalNotifications,
    private platform: Platform, 
    private bgMode: BackgroundMode,
    private notificationService: NotificationsService,
    private translate: TranslateService) { 
      this.initializeNotificationCheck();
  }

  initializeNotificationCheck() {
    this.platform.ready().then(() => {
        // Enable Background
        this.bgMode.setDefaults({
          silent: true
        });
        this.bgMode.on('enable').subscribe(() => {
          setInterval(() => this.showNotification(), GLOBAL.LOCAL_NOTIFICATION_CHECK_INTERVAL);
        });
        this.bgMode.enable();
        console.log(this.bgMode.isActive());
    });
  }

  public showNotification()
  {
    this.notificationService.getNotifications({}).subscribe(response => {
        if(response.results.length > 0){
          this.localNotifications.schedule({
            id: 1,
            text: this.translate.instant('notifications.alert')
          });
        }
    }, error => {
        console.error();
    });

  }

  
}
