import { Injectable } from '@angular/core';
import { ApiclientService } from '../../apiclient/apiclient.service';
import { Budget } from 'src/app/models/budget/budget';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(private apiclient: ApiclientService) {

  }

  /**
   * GET Portfolio data.
   * @param params
   * @returns {Observable<any>}
   */
  getPortfolios(params: any) {
      return this.apiclient.doGetCall('portfolios', params);
  }

  /**
   * GET Default Portfolio data.
   * @param params 
   */
  getDefaultPortfolios(params: any) {
    return this.apiclient.doGetCall('default_portfolios', params);
  }

  /**
   * Transform json to Budget object.
   * @param budget
   * @returns {Budget}
   */
  roadMapper(budget: any): Budget {

    return new Budget(
      (budget.id !== undefined) ? budget.id : "",
      (budget.code !== undefined) ? budget.code : "",
      (budget.alias !== undefined) ? budget.alias : "",
      (budget.ccv !== undefined) ? budget.ccv : "",
      (budget.relation !== undefined) ? budget.relation : "",
      (budget.holder_names !== undefined && budget.holder_names !== []) ? budget.holder_names.map(name => {
        return name;
      }) : [],
      (budget.operating !== undefined) ? budget.operating : "",
      (budget.valid_start_date !== undefined) ? budget.valid_start_date : "",
      (budget.valid_end_date !== undefined) ? budget.valid_end_date : "",
    );

  }



  /**
   * Update portfolio alias
   * @param {string} portfolio
   * @param {string} alias
   * @returns {Observable<any>}
   */
  updatePorfolioAlias(portfolio: string, alias: string): Observable<any> {

    return this.apiclient.doPostCall('portfolios', {
      portfolio,
      alias
    });

  }

}
