import { Injectable } from '@angular/core';
import { GLOBAL } from '../../global/global';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionDataService } from '../sessionData/session-data.service';


@Injectable({
  providedIn: 'root'
})
export class ApiclientService {

  endpoint = GLOBAL.API_REST_IP;
  constructor(private http: HttpClient,private sessionDataService:SessionDataService) { }

  /**
   * GET Request to API.
   * @param {string} request
   * @param paramsToSearch
   * @returns {Observable<any>}
   */
  doGetCall(request: string, paramsToSearch: any): Observable<any> {

    let options = {};
    let url = this.endpoint + request;
    let params = this.getHttpParams(paramsToSearch);
    let sizeParamsToSearch: number = this.getSizeOfObject(paramsToSearch);

    let type = false;
    if (request === 'get_document') type = true;

    options = this.getOptionsHeaders(params, sizeParamsToSearch, request, type);
    
    return this.http.get(url, options);

  }

  /**
   * POST request to API
   * @param {string} request
   * @param paramsToSearch
   * @returns {Observable<any>}
   */
  doPostCall(request: string, params: any): Observable<any> {

    let url = this.endpoint + request;

    let params2 = undefined;
    let type = false;

    let sizeParamsToSearch: number = this.getSizeOfObject(params2);
    let options = this.getOptionsHeaders(params2, sizeParamsToSearch, request, type);

    let formData = this.getFormData(params);

    return this.http.post(
      url,
      formData,
      options
    );

  }

  /**
   * POST Login request.
   * @param {string} username
   * @param {string} password
   * @returns {Observable<Object>}
   */
  doPostLogin(username: string, password: string) {

    var formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    let url = this.endpoint + 'get_user_token';
    return this.http.post(
      url,
      formData,
      options
    );
  }

  /**
   *
   * @param {HttpParams} params
   * @param {number} sizeParamsToSearch
   * @returns {any}
   */
  getOptionsHeaders(params: HttpParams, sizeParamsToSearch: number, request: string, type: boolean): any {

    let user = this.sessionDataService.getUser();
    let options = {}

    if (request == 'get_document' && type) {

      options = {
        headers: new HttpHeaders({
          'Authorization': 'Token' + ' ' + user?.token,
          'Content-Type': 'application/pdf',
        }),
        responseType: 'blob'
      };

    } else {

      options = {
        headers: new HttpHeaders({
          'Authorization': 'Token' + ' ' + user?.token,
        })
      };

    }

    (sizeParamsToSearch > 0)
        ? options = { ...options, params }
        : ""
    return options;

  }


  /**
   * Request Password Recovery.
   * @param {string} username
   * @returns {Observable<Object>}
   */
  requestRecoverPassword(username: string) {

    var formData = new FormData();
    formData.append("username", username);

    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };

    let url = this.endpoint + 'password_recovery';
    return this.http.post(
      url,
      formData,
      options
    );

  }

  /**
   * Request Password Change.
   * @param {string} username
   * @returns {Observable<Object>}
   */
  requestChangePassword(password: string, type: string, value: string) {

    var formData = new FormData();
    formData.append("new_password", password);

    if (type === "hash") formData.append("hash", value);
    else if (type === "username") formData.append("username", value);

    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Token ' + this.getToken(),
      })
    };

    let url = this.endpoint + 'change_password';
    return this.http.post(
      url,
      formData,
      options
    );

  }

  /**
    * Get FormData to Post.
    * @param params
    * @returns {FormData}
    */
  getFormData(params: any) {

    var formData = new FormData();

    for (let data of Object.entries(params)) {
      formData.append(data[0], data[1].toString());
    }

    return formData;

  }

  getOptionsHeaders2(params: HttpParams, sizeParamsToSearch: number) {
    let options = {}

    options = {
      headers: new HttpHeaders({
        'Authorization': 'Token af1cd01adc3088847ddbfa3cb27221b919420da9',
      })
    };

    (sizeParamsToSearch > 0) ? options = { ...options, params } : "";
    return options;
  }

  /**
*
* @param params
* @returns {number}
*/
  getSizeOfObject(params: any): number {

    if (params === null || params === undefined) {
      params = {}
    }

    return Object.keys(params).length;

  }
  /**
 *
 * @param queryParams
 * @returns {HttpParams}
 */
  getHttpParams(queryParams: any): HttpParams {

    if (queryParams.id === "" || queryParams.date_from === "" || queryParams.date_to === "" || queryParams.url === "" || queryParams.dni === "") {
      queryParams = {}
    }

    let params = new HttpParams();
    for (var p in queryParams) {
      params = params.set(p, queryParams[p]);
    }

    return params;
  }

  getToken() {

    let user = this.sessionDataService.getUser();
    return user?.token;

  }

}
