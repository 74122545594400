import {Injectable} from '@angular/core';
import {ApiclientService} from "../../apiclient/apiclient.service";
import {Notification} from "../../../models/notifications/notification";
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private apiClient: ApiclientService, private datePipe: DatePipe) {
  }

  /**
   * GET Notifications data from API.
   * @param params
   * @returns {Observable<any>}
   */
  getNotifications(params: any) {
    return this.apiClient.doGetCall('notifications', params);
  }

  /**
   * Call Get Document to Upload File.
   * @param params
   * @returns {Observable<any>}
   */
  readNotification(params: any) {
    return this.apiClient.doPostCall('notifications', params);
  }

  /**
   * JSON to Notification Object Array.
   * @param notifications
   * @returns {Notification[]}
   */
  roadMapper(notifications: any): Notification[] {

    let arr_notifications = [];

    if (notifications.length > 0) {

      for (let notification of notifications) {

        let notification_obj = this.roadMapperNotification(notification);
        arr_notifications.push(notification_obj);

      }
    }

    return arr_notifications;

  }

  /**
   * Transform json to Notification Object.
   * @param notification
   * @returns {Notification}
   */
  roadMapperNotification(notification: any): Notification {

    return new Notification(
      (notification.id !== undefined) ? notification.id : 0,
      (notification.message !== undefined) ? notification.message : "",
      (notification.date !== undefined) ? this.datePipe.transform(notification.date, 'dd/MM/yyyy') : "",
      (notification.url !== undefined) ? notification.url : "",
      (notification.document_type !== undefined) ? notification.document_type : "",
      (notification.status !== undefined) ? notification.status : "",
    );
  }
}
