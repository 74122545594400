export class Client {
    
    private _id: number;
    private _name: string;

    constructor(id: number, name: string) {

        this._id = id;
        this._name = name;

    }

    /**
     *
     * @returns {number}
     */
    public get id() {
        return this._id;
    }

    /**
     *
     * @param {number} id
     */
    public set id(id: number) {
        this._id = id;
    }

    /**
     *
     * @returns {string}
     */
    public get name() {
        return this._name;
    }

    /**
     *
     * @param {string} name
     */
    public set name(name: string) {
        this._name = name;
    }
}
