import { Injectable } from '@angular/core';
import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';
import { NotificationsService } from '../partials/document/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Proposal } from 'src/app/models/document/proposal';

@Injectable({
  providedIn: 'root'
})
export class DocumentViewerService {

  constructor(private documentViewer: DocumentViewer, private notificationService: NotificationsService, private translateService: TranslateService) { }




  viewDocument(urlFile: string, nameFile: string, contentType: string, docType: string, from: string, document: any,proposal?:Proposal) {
    const options: DocumentViewerOptions = {
      title: nameFile,
    };
    this.documentViewer.viewDocument(urlFile, contentType, options, ()=>this.onShow(docType, from, document,proposal), ()=>this.onClose(), ()=>this.onMissingApp(),()=> this.onError());
  }


  onShow(docType: string, from: string, document: any,proposal?:Proposal) {
    if ((document.statusClass === "approved") && from === 'list') {
      this.notificationService.reduceNotification(docType);

      document.status = this.translateService.instant("statusDocuments.read");
      document.statusClass = "read";
    }

    if (from === "proposal" && document.viewed === false) {
      document.viewed = true;
      proposal.updateReadDocuments();
    }
  }
  onClose() {
  }

  onMissingApp() {
  }

  onError() {
  }

}
