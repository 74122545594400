import { Injectable } from '@angular/core';
import { ApiclientService } from '../../apiclient/apiclient.service';
import { Client } from 'src/app/models/client/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private apiClient: ApiclientService) {
  }

  getClient(params) {
      return this.apiClient.doGetCall('clients', params);
  }

  roadMapper(client: any): Client {

      return new Client(
          (client.id !== undefined) ? client.id : 0,
          (client.name !== undefined) ? client.name : "",
      );

  }

}
