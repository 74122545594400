export class Budget {
  private _id: number;
  private _name: string;
  private _alias: string;
  private _ccv: number;
  private _titularName: string;
  private _titulars: string[];
  private _operating: boolean;
  private _start_date: string;
  private _end_date: string;

  constructor(id: number, name: string, alias: string, ccv: number, titularName: string, titulars: [], operating: boolean, start_date: string, end_date: string) {

    this._id = id;
    this._name = name;
    this._alias = alias;
    this._ccv = ccv;
    this._titularName = titularName;
    this._titulars = titulars;
    this._operating = operating;
    this._start_date = start_date;
    this._end_date = end_date;

  }

  /**
   *
   * @returns {number}
   */
  public get id() {
    return this._id;
  }

  /**
   *
   * @param {number} id
   */
  public set id(id: number) {
    this._id = id;
  }

  /**
   *
   * @returns {string}
   */
  public get name() {
    return this._name;
  }

  /**
   *
   * @param {string} name
   */
  public set name(name: string) {
    this._name = name;
  }

  /**
   *
   * @returns {string}
   */
  public get alias() {
    return this._alias;
  }

  /**
   *
   * @param {string} alias
   */
  public set alias(alias: string) {
    this._alias = alias;
  }

  /**
   *
   * @returns {number}
   */
  public get ccv() {
    return this._ccv;
  }

  /**
   *
   * @param {number} ccv
   */
  public set ccv(ccv: number) {
    this._ccv = ccv;
  }

  /**
   *
   * @returns {string}
   */
  public get titularName() {
    return this._titularName;
  }

  /**
   *
   * @param {string} titularName
   */
  public set titularName(titularName: string) {
    this._titularName = titularName;
  }


  /**
   *
   * @returns {string[]}
   */
  public get titulars() {
    return this._titulars;
  }

  /**
   *
   * @param {string[]} titulars
   */
  public set titulars(titulars: string[]) {
    this._titulars = titulars;
  }

  /**
   *
   * @returns {boolean}
   */
  public get operating() {
    return this._operating;
  }

  /**
   *
   * @param {boolean} operating
   */
  public set operating(operating: boolean) {
    this._operating = operating;
  }

  /**
   *
   * @returns {string}
   */
  public get end_date() {
    return this._end_date;
  }

  /**
   *
   * @param {string} end_date
   */
  public set end_date(end_date: string) {
    this._end_date = end_date;
  }

  /**
 *
 * @returns {string}
 */
  public get start_date() {
    return this._start_date;
  }

  /**
   *
   * @param {string} start_date
   */
  public set start_date(start_date: string) {
    this._start_date = start_date;
  }

  tooString() {
    return this.id + "-" + this.ccv + "-" + this.operating + "-" + this.titulars;
  }
}
