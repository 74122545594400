import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  private routeSelected = new BehaviorSubject<string>('dashboard');
  public routeSelected$ = this.routeSelected.asObservable();

  constructor(private router: Router) {

    this.router.events.subscribe((val) => {

      if (val instanceof NavigationEnd) {
        this.routeSelected.next(this.checkUrlName(val.urlAfterRedirects));

      }

    });

  }

  checkUrlName(rute: any): string {

    if (rute.includes('/maintenance')) {
      return 'maintenance';
    }

    return '';
  }


}
