import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule), canActivate: [AuthenticationGuard] },
  { path: 'help', loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule), canActivate: [AuthenticationGuard] },
  { path: 'maintenance', loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenancePageModule), canActivate: [AuthenticationGuard] },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule), canActivate: [AuthenticationGuard] },
  { path: 'change-password', loadChildren: () => import('./pages/change-password-page/change-password-page.module').then( m => m.ChangePasswordPagePageModule) },
  { path: 'my-portfolio', loadChildren: () => import('./pages/my-portfolio/my-portfolio.module').then(m => m.MyPortfolioPageModule), canActivate: [AuthenticationGuard] },
  { path: 'my-documents', loadChildren: () => import('./pages/my-documents/my-documents.module').then(m => m.MyDocumentsPageModule), canActivate: [AuthenticationGuard] },
  { path: 'recuperar_contrasena', loadChildren: () => import('./pages/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryPageModule) },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
