import { Injectable } from '@angular/core';
import { ApiclientService } from '../../apiclient/apiclient.service';

@Injectable({
  providedIn: 'root'
})
export class GetDocumentService {
  constructor(private apiclient: ApiclientService) {
  }

  getDocument(params: any) {
      return this.apiclient.doGetCall('get_document', params);
  }
}
