import { Budget } from '../budget/budget';

export class Filter {

    private _ids: string;
    private _budget_selected: Budget[];
    private _global_start_date: string;
    private _global_end_date: string;
    private _init_date_selected: string;
    private _end_date_selected: string;



    constructor(ids: string, budget_selected: Budget[], global_start_date: string, global_end_date: string, init_date_selected: string, end_date_selected: string) {

        this._ids = ids;
        this._budget_selected = budget_selected;
        this._global_start_date = global_start_date;
        this._global_end_date = global_end_date;
        this._init_date_selected = init_date_selected;
        this._end_date_selected = end_date_selected;

    }

    /**
   *
   * @returns {string}
   */
    public get ids() {
        return this._ids;
    }

    /**
     *
     * @param {string} ids
     */
    public set ids(ids: string) {
        this._ids = ids;
    }

    /**
   *
   * @returns {Budget[]}
   */
    public get budget_selected() {
        return this._budget_selected;
    }

    /**
     *
     * @param {Budget[]} budget_selected
     */
    public set budget_selected(budget_selected: Budget[]) {
        this.compareBudgetDates(budget_selected);
        this.getBudgetIds(budget_selected);
        this._budget_selected = budget_selected;
    }
    /**
     *
     * @returns {string}
     */
    public get global_start_date() {
        return this._global_start_date;
    }

    /**
     *
     * @param {string} global_start_date
     */
    public set global_start_date(global_start_date: string) {
        this._global_start_date = global_start_date;
    }

    /**
    *
    * @returns {string}
    */
    public get global_end_date() {
        return this._global_end_date;
    }

    /**
     *
     * @param {string} global_end_date
     */
    public set global_end_date(global_end_date: string) {
        this._global_end_date = global_end_date;
    }


    /**
  *
  * @returns {string}
  */
    public get init_date_selected() {
        return this._init_date_selected;
    }

    /**
     *
     * @param {string} init_date_selected
     */
    public set init_date_selected(init_date_selected: string) {
        this._init_date_selected = init_date_selected;
    }

    /**
  *
  * @returns {string}
  */
    public get end_date_selected() {
        return this._end_date_selected;
    }

    /**
     *
     * @param {string} end_date_selected
     */
    public set end_date_selected(end_date_selected: string) {
        this._end_date_selected = end_date_selected;
    }


    getBudgetIds(budget_selected: Budget[]) {
        let ids_collection: number[] = [];

        budget_selected.map(v => ids_collection.push(v.id));

        this.ids = this.transformBudgetIdArrayTostring(ids_collection);
    }

    compareBudgetDates(budget_selected: Budget[]) {


        let endDate = "";
        let startDate = "";
        budget_selected.forEach(val => {

            if (startDate === "") startDate = val.start_date;

            let s1 = new Date(startDate);
            let s2 = new Date(val.start_date);

            if (s2.getTime() < s1.getTime()) {
                startDate = val.start_date;
            }

            if (endDate === "") endDate = val.end_date;

            let d1 = new Date(endDate);
            let d2 = new Date(val.end_date);

            if (d2.getTime() > d1.getTime()) {
                endDate = val.end_date;
            }
        });

        this.global_end_date = endDate;
        this.global_start_date = startDate;
    }

    transformBudgetIdArrayTostring(budgetSelected: number[]): string {

        let text = budgetSelected.join(",");
        return text;

    }
}
